// extracted by mini-css-extract-plugin
export var header = "index-module--header--VIwe0";
export var visual = "index-module--visual--GBJeN";
export var namesWrapper = "index-module--namesWrapper--Tfs4g";
export var previous = "index-module--previous--qJLwF";
export var next = "index-module--next--uNLqB";
export var firstname = "index-module--firstname--Azydl";
export var lastname = "index-module--lastname--G8P2a";
export var note = "index-module--note--cfMEw";
export var name = "index-module--name--LFc7P";
export var category = "index-module--category--PpJDU";
export var body = "index-module--body--P-NxR";
export var intro = "index-module--intro--kenIu";
export var divider = "index-module--divider---FM-t";
export var text = "index-module--text--Qf5gW";
export var video = "index-module--video--9Otv+";
export var back = "index-module--back--tdHgs";
export var fadein = "index-module--fadein--Mmjik";