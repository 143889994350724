import * as styles from './index.module.scss'

import { graphql, Link } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"
import CallToAction from '../../components/calltoaction'
import Container from '../../components/container'
import Layout from '../../components/layout/index'
import Seo from '../../components/seo'
import TextRenderer from '../../components/text/renderer'
import VideoPlayer from '../../components/video/video'
import { pathTo } from '../../routes'

const Judge = ({ data: { judge }, pageContext: { previous, next } }) => {
  const { firstname, lastname, nameAlignment, category, body, intro, visual, youTubeVideo } = judge
  const title = `${firstname} ${lastname}`
  const alignment = nameAlignment ? nameAlignment : 'Left'

  return (
    <Layout>
      <Seo
        title={title}
        metaTitle={title}
        metaDescription={intro && intro.intro}
        sharingTitle={title}
        sharingDescription={intro && intro.intro}
        sharingImage={visual && visual.file && visual.file.url}
        currentUrl={pathTo(judge)}
      />
      <div className={styles.header}>
        <div className={styles.visual}>
          {previous &&
            <Link className={styles.previous} to={pathTo(previous)}>
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" fill="currentColor" />
              </svg>
            </Link>
          }
          <div className={styles.namesWrapper} data-alignment={alignment.toLowerCase()}>
            <Container>
              <div>
                <div className={styles.firstname}>{firstname}</div>
                <div className={styles.lastname}>{lastname}</div>
                {(category &&
                  <div className={styles.note}><b>Kategorie</b> {category.title}</div>
                )}
              </div>
            </Container>
          </div>
          {next &&
            <Link className={styles.next} to={pathTo(next)}>
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" fill="currentColor" />
              </svg>
            </Link>
          }
          <GatsbyImage image={visual?.gatsbyImageData} alt={title} />
        </div>
        <div>
          <Container>
            {(firstname && lastname &&
              <div className={styles.name}>
                {firstname} {lastname}
              </div>
            )}
            {(category &&
              <div className={styles.category}>
                Kategorie {category.title}
              </div>
            )}
          </Container>
        </div>
      </div>
      <div className={styles.body}>
        <Container>
          {(intro && intro.intro &&
            <div className={styles.intro}>
              <TextRenderer text={intro.intro} />
            </div>
          )}
          <div className={styles.divider} />
          {(body && body.body &&
            <div className={styles.text}>
              <TextRenderer text={body.body} />
            </div>
          )}
          {youTubeVideo?.youTubeUrl && (
            <div className={styles.video}>
              <VideoPlayer
                youTubeUrl={youTubeVideo?.youTubeUrl}
                previewImage={visual}
              />
            </div>
          )}
          <div className={styles.back}>
            <CallToAction ctaLabel='Zurück zur Übersicht' ctaLink='/jury' direction='left' />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Judge

export const query = graphql`
  query JudgeQuery($id: String!) {
    judge: contentfulJudge(id: { eq: $id }) {
      __typename
      id
      firstname
      lastname
      nameAlignment
      visual {
        id
        gatsbyImageData(
          aspectRatio: 1.7852348993
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          quality: 75
          width: 320
        )
      }
      slug
      category {
        id
        title
      }
      body {
        body
      }
      intro {
        intro
      }
      youTubeVideo {
        id
        youTubeUrl
      }
    }
  }
`
